<template>
  <div class="pageContol listWrap templateList formCom category1">
    <div class="framePage">
      <div class="framePage-title">
        <span>
            <em>当前位置：</em>
            <a href="javascript:;">试题管理</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div class="searchboxItem ci-full">
              <span class="itemLabel">工种类别:</span>
              <treeselect @getValue="getValue($event)" :value="valueId" :props="props" :options="categoryOptionData" :clearable="true"
                          :accordion="true"/>
            </div>
            <div class="searchboxItem ci-full">
              <span class="itemLabel">是否显示:</span>
              <el-select clearable v-model="searchIsShow" placeholder="请选择">
                <el-option v-for="item in showList" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </div>
            <div class="searchboxItem ci-full">
              <el-button class="bgc-bv" @click="getData(-1)">查询</el-button>
            </div>
            <div class="searchboxItem ci-full">
              <el-button class="bgc-bv" @click="questiongAdd()">新增试题</el-button>
            </div>
          </div>
          <div class="btnBox">
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table :data="tableData" ref="refTable" :header-cell-style="tableHeader" height="100%" size="small" style="width: 100%"
                      row-key="questionId">
              <el-table-column prop="categoryName" label="工种名称" align="left" width="400px"></el-table-column>
              <el-table-column prop="questionTypeName" label="试题类型" align="center"></el-table-column>
              <el-table-column prop="questionTitle" label="试题题干" align="left" width="400px"></el-table-column>
              <el-table-column prop="createTime" label="添加时间" align="center"></el-table-column>
              <el-table-column prop="address" label="操作" align="center">
                <div slot-scope="scope" class="">
                  <el-button size="mini" type="text" @click="openQuestionEditDialog(scope.row)">编辑</el-button>
                  <el-button size="mini" type="text" @click="questionDel(scope.row)">删除</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData"/>
      </div>
    </div>
    <!-- 编辑试题弹层start -->
    <miniQuestionEdit
        ref="miniQuestionEdit"
        @getnewdatap="getData"
        @subShows="subShows"
    />
    <!-- 新增试题弹层start -->
    <miniQuestionAdd
        ref="miniQuestionAdd"
        @getnewdatap="getData"
        @subShows="subShows"
    />
  </div>
</template>

<script>
import List from "@/mixins/List";
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import treeselect from "@/components/treeselect";
import miniQuestionEdit from "@/views/miniApp/questionManage/miniQuestionEditPop.vue";
import miniQuestionAdd from "@/views/miniApp/questionManage/miniQuestionAddPop.vue";

export default {
  name: "questionManage",
  mixins: [List],
  components: {
    treeselect,
    Empty,
    PageNum,
    miniQuestionEdit,
    miniQuestionAdd,
  },
  doNotInit: true,
  data() {
    return {
      valueId: "",
      props: {
        value: 'categoryCode',
        label: 'categoryName',
        children: 'children',
        // disabled:true
      },
      categoryOptionData: [],
      searchCategoryCode: "120101",

      miniQuestionDialogTitle: "试题编辑",
      miniQuestionDialogVisible: false,
      miniQuestionDialogFrom: {},
      miniQuestionDialogFromRules: {

      }
    }
  },
  created() {
    this.getCategoryTree();
  },
  methods: {
    //获取分类树下拉框
    getCategoryTree() {
      let params = {
        disabledLevels: "1,2"
      }
      this.$post("/miniapp/category/tree", params, 3000, true, 2)
          .then((res) => {
            if (res.status == 0) {
              this.categoryOptionData = res.data;
              return;
            }
            this.$message({
              type: "error",
              message: res.message ? res.message : "操作失败",
            });
          })
          .catch(() => {
            return;
          });
    },
    questionTypeFormatter(row, column, cellValue, index) {
      console.info(cellValue)
    },
    getValue(value) {
      this.searchCategoryCode = value;
    },
    //获取试题
    getData(pageNum = 1) {
      let params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        categoryCode: this.searchCategoryCode,
        showed: this.searchIsShow
      }
      this.doFetch(
          {
            url: "/miniapp/miniquestion/pageList",
            params,
            pageNum,
          },
          true,
          2
      );
    },
    openQuestionEditDialog(row) {
      this.$refs.miniQuestionEdit.showPopUp(row.questionId);
    },
    questiongAdd() {
      this.$refs.miniQuestionAdd.showPopUp();
    },
    // 编辑大纲
    questionEdit() {
      this.$refs["miniQuestionDialogFromRef"].validate((valid) => {
        if (valid) {
          this.$post(
              "/miniapp/question/modify",
              this.miniQuestionDialogFrom,
              3000,
              true,
              2
          ).then((res) => {
            this.$message({
              type: res.status == "0" ? "success" : "error",
              message: res.message ? res.message : "操作失败",
            });
            if (res.status == 0) {
              this.$refs[
                  "miniQuestionDialogFromRef"
                  ].resetFields();
              this.miniQuestionDialogVisible = false;
              this.getData();
            }
          }).catch(() => {
            return;
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    questionDel(info) {
      if (info.deleted == true) {
        this.$message({type: "error", message: "已删除，无法继续操作",});
        return;
      }
      this.$confirm("删除后，将不可恢复", "确定删除吗？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$post(
            "/miniapp/miniquestion/delete",
            info,
            3000,
            true,
            2
        ).then((ret) => {
          ret.status === "0"
              ? this.getData()
              : this.$message.error(ret.message || "刪除失败！");
        });
      }).catch(() => {
        return;
      });
    },
  }
}
</script>

<style scoped>

</style>