<template>
  <!-- 高级搜索 -->
  <el-dialog :visible.sync="centerDialogVisible" width="50%" top="0.5%" center @close="closeDialog" title="新增试题" :close-on-click-modal="false">
    <div style="height: 600px">
      <div class="ovy-a">
        <el-form ref="form" :model="form" label-width="80px" :rules="rules">
          <el-form-item label="工种类别" required >
            <treeselect @getValue="getValue($event)" :value="valueId" :props="props" :options="categoryOptionData" :clearable="true"
                        :accordion="true"/>
          </el-form-item>
          <el-form-item label="题目类型" required class="el-form-questionType">
            <el-radio-group v-model="form.questionType" @change="formTypeChange">
              <el-radio :label="0">单选题</el-radio>
              <el-radio :label="1">多选题</el-radio>
              <el-radio :label="2">判断题</el-radio>
              <el-radio :label="3">填空题</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="题目内容" class="areaBox" required>
            <div ref="questionTitleEditor" style="width: 100%; position: relative; z-index: 2"/>
            <span v-show="showquestionTitle" class="showtitle">请输入题目内容</span>
          </el-form-item>
          <el-form-item label v-if="form.questionType == 0" prop="answerCode">
              <el-radio-group v-model="form.answerCode">
                <div v-for="(item, index) in form.optionList" :key="index" style="display: flex; align-items: center; margin-bottom: 18px;">
                  <el-form-item :prop="'optionList[' + index + '].optionKey'">
                    <el-radio :label="form.optionList[index].optionKey">选项{{ form.optionList[index].optionKey }}</el-radio>
                    <el-input v-model="form.optionList[index].optionContent" style="width: 220px"></el-input>
                    <el-upload :on-change="(res, file) => {return handleAvatarSuccess(res, file, index);}"
                        :before-upload="$beforeAvatarUpload" :http-request="$requestMine" :show-file-list="false"
                        class="img-el-upload" action accept="image/png, image/gif, image/jpg, image/jpeg">
                      <el-image :src=" form.optionList[index].optionSpecialContent || require('@/assets/develop.png')" fit="contain" class="imgCenter"></el-image>
                    </el-upload>
                    <el-button v-if="index > 1" class="delet" type="text" @click="delOpenList(index)">删除选项</el-button>
                    <el-button v-if="form.answerCode == form.optionList[index].optionKey" class="delet" type="text">正确答案</el-button>
                  </el-form-item>
                </div>
              </el-radio-group>
          </el-form-item>
          <el-form-item label v-if="form.questionType == 1" prop="answerCode">
            <el-radio-group v-model="form.optionListall">
              <div v-for="(item, index) in form.optionListall" :key="index" style="display: flex; align-items: center; margin-bottom: 18px">
                <el-form-item :prop="'optionListall[' + index + '].optionContent'" class="checkbox">
                  <el-checkbox v-model="form.optionListall[index].optionIsCorrect" :label="form.optionListall[index].optionKey">选项{{ form.optionListall[index].optionKey }}
                    <el-input v-model="form.optionListall[index].optionContent" style="width: 220px"></el-input>
                  </el-checkbox>
                  <el-upload :on-change="(res, file) => {return handleAvatarSuccess(res, file, index);}"
                      :before-upload="$beforeAvatarUpload" :http-request="$requestMine"
                      :show-file-list="false" class="img-el-upload" action accept="image/png, image/gif, image/jpg, image/jpeg">
                    <el-image :src="form.optionListall[index].optionSpecialContent || require('@/assets/develop.png')" fit="contain" class="imgCenter"></el-image>
                  </el-upload>
                  <el-button v-if="form.optionListall[index].optionIsCorrect" type="text" class="delet">正确答案</el-button>
                  <el-button v-if="index > 2" type="text" class="delet" @click="delOpenList(index)">删除</el-button>
                </el-form-item>
              </div>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="正确答案" v-if="form.questionType == 2" prop="answerCode">
            <el-radio-group v-model="form.answerCode">
              <el-radio label="T" @change="debloChangeList(index)">正确</el-radio>
              <el-radio label="F" @change="debloChangeList(index)">错误</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="正确答案" v-if="form.questionType == 3" prop="answerCode">
            <el-input type="textarea" :rows="3" placeholder="请输入正确答案" v-model="form.answerCode" style="width: 100%"></el-input>
          </el-form-item>
          <el-form-item label v-if="(form.questionType == 0 || form.questionType == 1) && form.optionList.length < 6 && form.optionListall.length < 6">
            <el-button @click="addFromOpenList">添加选项</el-button>
          </el-form-item>
          <el-form-item label="题目解析" required>
            <div ref="questionAnalysisEditor" style="width: 100%; position: relative; z-index: 2"/>
            <span v-show="showQuestionAnalysis" class="showtitle">请输入答案解析</span>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button class="bgc-bv" @click="docancle()">取 消</el-button>
          <el-button class="bgc-bv" @click="addOptions">确 定</el-button>
        </span>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import List from "@/mixins/List";
import E from "wangeditor";
import treeselect from "@/components/treeselect";

let questionTitleEditor = {};
let questionAnalysisEditor = {};
export default {
  name: "miniQuestionPaperPop",
  components: {
    // Empty,
    treeselect,
  },
  mixins: [List],
  data() {
    return {
      categoryOptionData: [],
      props: {
        value: 'categoryCode',
        label: 'categoryName',
        children: 'children',
        // disabled:true
      },
      letterArr: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
      ],
      activeName: "first",
      showQuestionAnalysis: false,
      showquestionTitle: false,
      centerDialogVisible: false,
      form: {
        categoryCode: "",
        answerCode:"",
        questionAnswer: "",
        questionType: 0,
        optionList: [
          {
            optionKey: "A",
            optionContent: "",
            optionSpecialContent: "",
            optionIsCorrect: false,
          },
          {
            optionKey: "B",
            optionContent: "",
            optionSpecialContent: "",
            optionIsCorrect: false,
          },
        ],
        optionListall: [
          {
            optionKey: "A",
            optionContent: "",
            optionSpecialContent: "",
            optionIsCorrect: false,
          },
          {
            optionKey: "B",
            optionContent: "",
            optionSpecialContent: "",
            optionIsCorrect: false,
          },
          {
            optionKey: "C",
            optionContent: "",
            optionSpecialContent: "",
            optionIsCorrect: false,
          },
          {
            optionKey: "D",
            optionContent: "",
            optionSpecialContent: "",
            optionIsCorrect: false,
          },
        ],
      },
      ruleForm: {},
      rules: {
        answerCode: [
          {
            required: true,
            message: "请选择一个正确答案项",
            trigger: ["blur", "change"],
          },
        ],
        "optionListall[index].optionIsCorrect": [
          {
            required: true,
            message: "请选择至少一个正确答案项",
            trigger: "change",
          },
        ],
      },
    };
  },
  created() {
    this.getCategoryTree();
  },
  mounted() {
  },
  beforeDestroy() {
    questionTitleEditor = {};
    questionAnalysisEditor = {};
  },
  computed: {},
  methods: {
    init() {
    },
    radioChange(value) {
      console.info(value)
    },
    //获取分类树下拉框
    getCategoryTree() {
      let params = {
        disabledLevels: "1,2"
      }
      this.$post("/miniapp/category/tree", params, 3000, true, 2)
          .then((res) => {
            if (res.status == 0) {
              this.categoryOptionData = res.data;
              return;
            }
            this.$message({
              type: "error",
              message: res.message ? res.message : "操作失败",
            });
          })
          .catch(() => {
            return;
          });
    },
    getValue(value) {
      this.form.categoryCode = value;
    },
    showPopUp() {
      const that = this;
      that.centerDialogVisible = true;
      let arr = Object.keys(questionTitleEditor);
      if (arr.length == 0) {
        that.$nextTick(function () {
          that.editorInit();
          that.editorInit2();
          that.form.questionTitle = questionTitleEditor.txt.html("");
          that.form.questionAnalysis = questionAnalysisEditor.txt.html("");
        });
      }
    },
    // 富文本
    editorInit() {
      questionTitleEditor = new E(this.$refs.questionTitleEditor);
      questionTitleEditor.config.onchange = (html) => {
        this.ruleForm.content = html;
      };
      questionTitleEditor.config.menus = [
        "head", // 标题
        "bold", // 粗体
        "fontSize", // 字号
        "italic", // 斜体
        "underline", // 下划线
        "strikeThrough", // 删除线
        "foreColor", // 文字颜色
        "backColor", // 背景颜色
        "list", // 列表
        "justify", // 对齐方式
        "quote", // 引用
        "emoticon", // 表情
        "image", // 插入图片
        "table", // 表格
        "undo", // 撤销
        "redo", // 重复
      ];
      questionTitleEditor.config.uploadImgMaxSize = 1 * 1024 * 1024;
      // editor.config.pasteFilterStyle = "text";
      questionTitleEditor.config.pasteIgnoreImg = true;
      questionTitleEditor.config.pasteFilterStyle = false;
      questionTitleEditor.config.customUploadImg = (files, insert) => {
        let extension = files[0].name.substring(
            files[0].name.lastIndexOf(".") + 1,
            files[0].name.length
        );
        let formData = new FormData();
        formData.append("file ", files[0]);
        formData.append("fileType ", extension);
        formData.append("folder ", "LECTURER");
        formData.append("isPublic ", true);
        this.$Postformat("/sys/upload", formData)
            .then((result) => {
              insert(result.data.fileURL);
            })
            .catch(() => {
              setTimeout(() => {
                this.$message({
                  type: "warning",
                  message: "上传失败",
                });
              }, 300);
            });
      };
      questionTitleEditor.config.pasteTextHandle = function (content) {
        // content 即粘贴过来的内容（html 或 纯文本），可进行自定义处理然后返回
        // return content + '<p>在粘贴内容后面追加一行</p>'
        if (content == "" && !content) return "";
        let input = content;
        // 1. remove line breaks / Mso classes
        var stringStripper = /(\n|\r| class=(")?Mso[a-zA-Z]+(")?)/g;
        var output = input.replace(stringStripper, " ");
        // 2. strip Word generated HTML comments
        var commentSripper = new RegExp("<!--(.*?)-->", "g");
        var output = output.replace(commentSripper, "");
        var tagStripper = new RegExp(
            "<(/)*(meta|link|span|\\?xml:|st1:|o:|font)(.*?)>",
            "gi"
        );
        // 3. remove tags leave content if any
        output = output.replace(tagStripper, "");
        // 4. Remove everything in between and including tags '<style(.)style(.)>'
        var badTags = [
          "style",
          "script",
          "applet",
          "embed",
          "noframes",
          "noscript",
        ];

        for (var i = 0; i < badTags.length; i++) {
          tagStripper = new RegExp(
              "<" + badTags[i] + ".*?" + badTags[i] + "(.*?)>",
              "gi"
          );
          output = output.replace(tagStripper, "");
        }
        // 5. remove attributes ' style="..."'
        var badAttributes = ["style", "start"];
        for (var i = 0; i < badAttributes.length; i++) {
          var attributeStripper = new RegExp(
              " " + badAttributes[i] + '="(.*?)"',
              "gi"
          );
          output = output.replace(attributeStripper, "");
        }
        console.log("output----", output);
        return output;
      };
      questionTitleEditor.create();
    },
    editorInit2() {
      questionAnalysisEditor = new E(this.$refs.questionAnalysisEditor);
      questionAnalysisEditor.config.onchange = (html) => {
        this.ruleForm.content = html;
      };
      questionAnalysisEditor.config.menus = [
        "head", // 标题
        "bold", // 粗体
        "fontSize", // 字号
        "italic", // 斜体
        "underline", // 下划线
        "strikeThrough", // 删除线
        "foreColor", // 文字颜色
        "backColor", // 背景颜色
        "list", // 列表
        "justify", // 对齐方式
        "quote", // 引用
        "emoticon", // 表情
        "image", // 插入图片
        "table", // 表格
        "undo", // 撤销
        "redo", // 重复
      ];
      questionAnalysisEditor.config.uploadImgMaxSize = 1 * 1024 * 1024;
      // editor.config.pasteFilterStyle = "text";
      questionAnalysisEditor.config.pasteIgnoreImg = true;
      questionAnalysisEditor.config.pasteFilterStyle = false;
      questionAnalysisEditor.config.customUploadImg = (files, insert) => {
        let extension = files[0].name.substring(
            files[0].name.lastIndexOf(".") + 1,
            files[0].name.length
        );
        let formData = new FormData();
        formData.append("file ", files[0]);
        formData.append("fileType ", extension);
        formData.append("folder ", "LECTURER");
        formData.append("isPublic ", true);
        this.$Postformat("/sys/upload", formData)
            .then((result) => {
              insert(result.data.fileURL);
            })
            .catch(() => {
              setTimeout(() => {
                this.$message({
                  type: "warning",
                  message: "上传失败",
                });
              }, 300);
            });
      };
      questionAnalysisEditor.config.pasteTextHandle = function (content) {
        // content 即粘贴过来的内容（html 或 纯文本），可进行自定义处理然后返回
        // return content + '<p>在粘贴内容后面追加一行</p>'
        if (content == "" && !content) return "";
        let input = content;
        // 1. remove line breaks / Mso classes
        var stringStripper = /(\n|\r| class=(")?Mso[a-zA-Z]+(")?)/g;
        var output = input.replace(stringStripper, " ");
        // 2. strip Word generated HTML comments
        var commentSripper = new RegExp("<!--(.*?)-->", "g");
        var output = output.replace(commentSripper, "");
        var tagStripper = new RegExp(
            "<(/)*(meta|link|span|\\?xml:|st1:|o:|font)(.*?)>",
            "gi"
        );
        // 3. remove tags leave content if any
        output = output.replace(tagStripper, "");
        // 4. Remove everything in between and including tags '<style(.)style(.)>'
        var badTags = [
          "style",
          "script",
          "applet",
          "embed",
          "noframes",
          "noscript",
        ];

        for (var i = 0; i < badTags.length; i++) {
          tagStripper = new RegExp(
              "<" + badTags[i] + ".*?" + badTags[i] + "(.*?)>",
              "gi"
          );
          output = output.replace(tagStripper, "");
        }
        // 5. remove attributes ' style="..."'
        var badAttributes = ["style", "start"];
        for (var i = 0; i < badAttributes.length; i++) {
          var attributeStripper = new RegExp(
              " " + badAttributes[i] + '="(.*?)"',
              "gi"
          );
          output = output.replace(attributeStripper, "");
        }
        console.log("output----", output);
        return output;
      };
      questionAnalysisEditor.create();
    },
    optionFM(msg) {
      if (msg == 0) {
        return "A";
      } else if (msg == 1) {
        return "B";
      } else if (msg == 2) {
        return "C";
      } else if (msg == 3) {
        return "D";
      } else if (msg == 4) {
        return "E";
      } else if (msg == 5) {
        return "F";
      }
    },
    addOptions() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (questionAnalysisEditor.txt.text().trim() == "") {
            this.showQuestionAnalysis = true;
            return false;
          } else if (questionTitleEditor.txt.text().trim() == "") {
            this.showquestionTitle = true;
            return false;
          } else {
            this.showQuestionAnalysis = false;
            this.showquestionTitle = false;
            const that = this;
            let parmar = {
              categoryCode: that.form.categoryCode,
              questionTitle: questionTitleEditor.txt.text().trim(),
              questionType: that.form.questionType,
              questionAnswer: "",
              answerCode: "",
              questionAnalysis: questionAnalysisEditor.txt.text().trim(),
            };
            if (that.form.questionType == 0) {
              parmar.optionJson = JSON.stringify(that.form.optionList)
              for (let i = 0; i < that.form.optionList.length; i++) {
                if (that.form.optionList[i].optionIsCorrect) {
                  parmar.answerCode = that.form.optionList[i].optionKey
                  parmar.questionAnswer = that.form.optionList[i].optionContent
                }
              }
            } else if (that.form.questionType == 1) {
              let mulAnswers = []
              let answerCodeTemp = []
              for (let i = 0; i < that.form.optionListall.length; i++) {
                if (that.form.optionListall[i].optionIsCorrect) {
                  mulAnswers.push(that.form.optionListall[i].optionContent + (that.form.optionListall[i].optionSpecialContent||""))
                  answerCodeTemp.push(this.letterArr[i])
                }
              }
              parmar.answerCode = answerCodeTemp.join(",")
              parmar.questionAnswer = mulAnswers.join("#SUNREAL#")
              parmar.optionJson = JSON.stringify(that.form.optionListall)
            }
            console.info(parmar)
            that.$post("/miniapp/miniquestion/insert", parmar, 3000, true, 2)
                .then((ret) => {
                  if (ret.status == "0") {
                    this.$message({
                      message: ret.message,
                      type: "success",
                    });
                  }
                  that.centerDialogVisible = false;
                  that.empty();
                  that.$emit("getnewdatap");
                  that.$emit("subShows", true);
                  if (ret.status == "-1") {
                    this.$message.error(ret.message);
                  }
                }).catch((err) => {
                  return;
                });
          }
        }
      });
    },
    delOpenList(index) {
      if (this.form.questionType == 0) {
        this.form.optionList.splice(index, 1);
      } else if (this.form.questionType == 1) {
        this.form.optionListall.splice(index, 1);
      }
    },
    addFromOpenList() {
      let listLength = this.form.optionList.length;
      if (this.form.questionType == 1) {
        listLength = this.form.optionListall.length;
      }
      let data = {
        optionKey: this.optionFM(listLength),
        optionContent: "",
        optionSpecialContent: "",
        optionIsCorrect: false,
      };
      if (this.form.questionType == 0) {
        this.form.optionList.push(data);
      } else if (this.form.questionType == 1) {
        this.form.optionListall.push(data);
      }
      this.$forceUpdate();
    },
    docancle() {
      this.centerDialogVisible = false;
      this.empty();
    },
    empty() {
      this.form = {
        questionType: 0,
        questionAnswer: "",
        optionList: [
          {
            optionKey: "A",
            optionContent: "",
            optionSpecialContent: "",
            optionIsCorrect: false,
          },
          {
            optionKey: "B",
            optionContent: "",
            optionSpecialContent: "",
            optionIsCorrect: false,
          },
        ],
        optionListall: [
          {
            optionKey: "A",
            optionContent: "",
            optionSpecialContent: "",
            optionIsCorrect: false,
          },
          {
            optionKey: "B",
            optionContent: "",
            optionSpecialContent: "",
            optionIsCorrect: false,
          },
          {
            optionKey: "C",
            optionContent: "",
            optionSpecialContent: "",
            optionIsCorrect: false,
          },
          {
            optionKey: "D",
            optionContent: "",
            optionSpecialContent: "",
            optionIsCorrect: false,
          },
        ],
      };
      this.$refs["form"].resetFields();
      questionTitleEditor.txt.clear();
      questionAnalysisEditor.txt.clear();
    },
    closeDialog() {
      this.centerDialogVisible = false;
      this.empty();
    },
    formTypeChange(value) {
      this.$refs["form"].resetFields();
      questionTitleEditor.txt.clear();
      questionAnalysisEditor.txt.clear();
      this.form = {
        questionType: value,
        optionList: [
          {
            optionKey: "A",
            optionContent: "",
            optionSpecialContent: "",
            optionIsCorrect: false,
          },
          {
            optionKey: "B",
            optionContent: "",
            optionSpecialContent: "",
            optionIsCorrect: false,
          },
        ],
        optionListall: [
          {
            optionKey: "A",
            optionContent: "",
            optionSpecialContent: "",
            optionIsCorrect: false,
          },
          {
            optionKey: "B",
            optionContent: "",
            optionSpecialContent: "",
            optionIsCorrect: false,
          },
          {
            optionKey: "C",
            optionContent: "",
            optionSpecialContent: "",
            optionIsCorrect: false,
          },
          {
            optionKey: "D",
            optionContent: "",
            optionSpecialContent: "",
            optionIsCorrect: false,
          },
        ],
        questionAnswer: "",
      };
    },
    //图片上传
    handleAvatarSuccess(res, fiie, index) {
      let extension = res.name.substring(
          res.name.lastIndexOf(".") + 1,
          res.name.length
      );
      let formData = new FormData();
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      formData.append("folder ", "LECTURER");
      formData.append("isPublic ", true);
      const that = this;
      that.$Postformat("/sys/upload", formData)
          .then((result) => {
            if (that.form.questionType == 0) {
              that.form.optionList[index].optionSpecialContentKey = result.data.fileKey || "";
              that.form.optionList[index].optionSpecialContent = result.data.fileURL || "";
            } else {
              that.form.optionListall[index].optionSpecialContentKey = result.data.fileKey || "";
              that.form.optionListall[index].optionSpecialContent = result.data.fileURL || "";
            }
          })
          .catch(() => {
            setTimeout(() => {
              that.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
    },
  },
};
</script>
<style lang="less">
.el-input-number {
  .el-input__inner {
    line-height: 40px;
    height: 40px;
  }
}

.showtitle {
  color: #f56c6c;
  font-size: 12px;
  line-height: 1;
  padding-top: 4px;
  position: absolute;
  top: 100%;
  left: 0;
}

.el-form-questionType /deep/ .el-form-item__content {
  margin-left: 0 !important;
}

.w-e-text-container {
  height: 250px !important; /*!important是重点，因为原div是行内样式设置的高度300px*/
}
</style>
<style lang="less" scoped>
.el-form-questionType {
  display: flex;
}

.el-form-item__content {
  display: flex;
  align-items: center;

  .el-radio-group .el-form-item /deep/ .el-form-item__content {
    display: flex;
    align-items: center;
  }

  .checkbox /deep/ .el-form-item__content {
    display: flex;
    align-items: center;

    .el-checkbox {
      display: flex;
      align-items: center;

      .el-checkbox__label {
        display: flex;
        align-items: center;

        .el-input {
          margin-left: 1.5rem;
        }
      }
    }
  }
}

.delet {
  margin-left: 0.5rem;
}

.el-form-item__content .img-el-upload /deep/ .el-upload {
  margin-left: 15px;
  width: 30px;
  height: 30px !important;
}

.dialog-footer {
  display: flex;
  justify-content: center;
}
</style>
